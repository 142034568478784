
import homeQuery from "@/apollo/queries/pages/home.gql";
import lenisScrollMixin from '@/mixins/lenisScrollMixin';
import previewModeMixin from '@/mixins/previewModeMixin';
import seoMixin from '@/mixins/seoMixin';

export default {
  name: 'index',
  colorMode: 'light',
  mixins: [lenisScrollMixin, previewModeMixin, seoMixin],
  data() {
    return {
      home: null,
    };
  },
  async asyncData({app, store}) {
    const previewMode = store.state.previewMode;

    if (previewMode) {
      await app.apolloProvider.defaultClient.resetStore();
    }

    const responseHome = await app.apolloProvider.defaultClient.query({
      query: homeQuery,
      variables: {
        locale: app.i18n?.locale || 'en',
      },
    }).catch((err) => {
      console.log(JSON.stringify(err, null, 2));
    });

    const home = responseHome.data?.home;

    return {
      home,
      previewMode,
    };
  },
  methods: {
    getPageData() {
      return this.home?.data;
    },
  },
}
